<template>
  <div class="main">
    <div :class="isFixed ? 'nav' : 'headerctn'" id="nav">
      <div class="header">
        <div class="left">
          <img class="logo" src="../assets/img/logo.png" alt="">
        </div>
        <ul class="logotext" id="getli">
          <li :class="tabIndex == 0 ? 'active' : ''" @click="tabIndex = 0; setFloorClick(0)">
            <div class="logodiv">
              <span>站长推荐</span>
            </div>
            <i class="logo_line"></i>
          </li>
          <li :class="tabIndex == 1 ? 'active' : ''" @click="tabIndex = 1; setFloorClick(1)">
            <div class="logodiv">
              <span>AV视频</span>
            </div>
            <i class="logo_line"></i>
          </li>
          <li :class="tabIndex == 2 ? 'active' : ''" @click="tabIndex = 2; setFloorClick(2)">
            <div class="logodiv">
              <span>成人抖阴</span>
            </div>
            <i class="logo_line"></i>
          </li>
          <li :class="tabIndex == 3 ? 'active' : ''" @click="tabIndex = 3; setFloorClick(3)">
            <div class="logodiv">
              <span>禁漫爽文</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="logotisi">
        温馨提示：因含成人内容，被系统误判为恶意软件，本站承诺绝不含恶意程序，请放心安装使用。
      </div>
    </div>

    <div class="item1_zztj">
      <div class="zztjtop" id="other1">
        <span class="tsi"></span>
        <p class="zztjtoptext">站长推荐</p>
        <div class="look"><img class="look-img" src="../assets/img/look.gif" /></div>
      </div>
      <ul class="zztj_ul">
        <li class="zztj_li" v-for="(item, index) in data1" :key="index">
          <div class="zztj_iocn" @click="downloadClick(item.btn)">
            <img class="iocn1" v-lazy="item.url" alt="" />
          </div>
          <p class="zztj_text">{{ item.text }}</p>
          <div class="btn1" @click="downloadClick(item.btn)">
            <span></span>
            <p>下载</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="item1_av" id="other2">
      <div class="zztjtop">
        <span class="tsi"></span>
        <p class="zztjtoptext">AV视频</p>
        <div class="look"><img class="look-img" src="../assets/img/look.gif" /></div>
      </div>
    </div>
    <ul class="av-ctn">
      <li v-for="(item, index) in dataAv" :key="index">
        <div class="av_img">
          <div class="av_iocn" v-if="item.isImg" @click="downloadClick(item.btn)">
            <img class="iocn2" v-lazy="item.url" alt="" />
          </div>
          <div v-else class="video_post">
            <Player @play="playState1" :ref="`av_video${index + 1}`" :setreplay="true">
              <source :src="item.url" type="video/mp4">
              <template v-slot:cover>
                <div class="v-cover">
                  <img v-if="item.imguUrl" v-lazy="item.imguUrl" />
                </div>
              </template>
              <template v-slot:playicon>
                <div class="v-play-icon">
                  <img src="../assets/img/play-icon.png">
                </div>
              </template>
              <template v-slot:replay>
                <div class="replay-container bg01" @click.stop>
                  <div class="replay-btn" @click.stop="replayVideoFunc('av_', index + 1)">
                    <i class="replay-icon"></i>
                    <span class="replay-btn-text">重新播放</span>
                  </div>
                  <div class="replay-download" @click.stop="downloadClick(item.btn)">
                    <span class="icon33"></span>
                    <a href="javascript:;" class="dow_text">下载APP观看完整视频</a>
                  </div>
                </div>
              </template>
            </Player>
          </div>
        </div>
        <div class="av_text">
          <p class="av_textrig" v-if="item.title">{{ item.title }}</p>
          <div class="av_btn" @click="downloadClick(item.btn)">
            <span></span>
            <p class="av_textxia">下载</p>
          </div>
        </div>
        <div class="av_title">{{ item.text }}</div>
      </li>

    </ul>
    <div class="item1_av" id="other3">
      <div class="zztjtop">
        <span class="tsi"></span>
        <p class="zztjtoptext">成人抖阴</p>
        <div class="look"><img class="look-img" src="../assets/img/look.gif" /></div>
      </div>
    </div>
    <ul class="duan-ctn">
      <li v-for="(item, index) in data3" :key="index">
        <div class="duan_img">
          <div class="av_iocn" v-if="item.isImg" @click="downloadClick(item.btn)">
            <img class="iocn2" v-lazy="item.url" alt="" />
          </div>
          <div v-else class="video_post">
            <Player @play="playState1" :ref="`av_video${index + 2}`" :setreplay="true">
              <source :src="item.url" type="video/mp4">
              <template v-slot:cover>
                <div class="v-cover">
                  <img v-if="item.imguUrl" v-lazy="item.imguUrl" />
                </div>
              </template>
              <template v-slot:playicon>
                <div class="v-play-icon">
                  <img src="../assets/img/play-icon.png">
                </div>
              </template>
              <template v-slot:replay>
                <div class="replay-container bg01" @click.stop>
                  <div class="replay-btn replay-btn2" @click.stop="replayVideoFunc('av_', index + 2)">
                    <i class="replay-icon"></i>
                    <span class="replay-btn-text">重新播放</span>
                  </div>
                  <div class="replay-download replay-download2" @click.stop="downloadClick(item.btn)">
                    <span class="icon33"></span>
                    <a href="javascript:;" class="dow_text">下载APP观看</a>
                  </div>
                </div>
              </template>
              <template v-slot:fenxiang>
                <div class="fenxiang">
                  <div class="btn_fx1"></div>
                  <div class="btn_fx2"></div>
                  <div class="btn_fx3"></div>
                  <div class="btn_fx4"></div>
                </div>
                <p class="fenxiang2">{{ item.title }}</p>
              </template>
            </Player>
          </div>
          <div class="fenxiang">
            <div class="btn_fx1"></div>
            <div class="btn_fx2"></div>
            <div class="btn_fx3"></div>
            <div class="btn_fx4"></div>
          </div>
          <p class="fenxiang2">{{ item.title }}</p>
        </div>
        <div class="duan_text">
          <p class="duan_textrig" v-if="item.title">{{ item.title }}</p>
          <div class="duan_btn" @click="downloadClick(item.btn)">
            <span></span>
            <p class="duan_textxia">下载</p>
          </div>
        </div>
        <div class="duan_title">{{ item.text }}</div>
      </li>

    </ul>
    <div class="item1_av" id="other4">
      <div class="zztjtop">
        <span class="tsi"></span>
        <p class="zztjtoptext">禁漫爽文</p>
        <div class="look"><img class="look-img" src="../assets/img/look.gif" /></div>
      </div>
    </div>
    <ul class="av-ctn">
      <li v-for="(item, index) in data4" :key="index">
        <div class="av_img">
          <div class="av_iocn" v-if="item.isImg" @click="downloadClick(item.btn)">
            <img class="iocn2" v-lazy="item.url" alt="" />
          </div>
          <div v-else class="video_post">
            <Player @play="playState1" :ref="`av_video${index + 3}`" :setreplay="true">
              <source :src="item.url" type="video/mp4">
              <template v-slot:cover>
                <div class="v-cover">
                  <img v-if="item.imguUrl" v-lazy="item.imguUrl" />
                </div>
              </template>
              <template v-slot:playicon>
                <div class="v-play-icon">
                  <img src="../assets/img/play-icon.png">
                </div>
              </template>
              <template v-slot:replay>
                <div class="replay-container bg01" @click.stop>
                  <div class="replay-btn" @click.stop="replayVideoFunc('av_', index + 3)">
                    <i class="replay-icon"></i>
                    <span class="replay-btn-text">重新播放</span>
                  </div>
                  <div class="replay-download" @click.stop="downloadClick(item.btn)">
                    <span class="icon33"></span>
                    <a href="javascript:;" class="dow_text">下载APP观看完整视频</a>
                  </div>
                </div>
              </template>
            </Player>
          </div>
        </div>
        <div class="av_text">
          <p class="av_textrig" v-if="item.title">{{ item.title }}</p>
          <div class="av_btn" @click="downloadClick(item.btn)">
            <span></span>
            <p class="av_textxia">下载</p>
          </div>
        </div>
        <div class="av_title">{{ item.text }}</div>
      </li>

    </ul>
    <div class="item1_av" id="other5">
      <div class="zztjtop">
        <span class="tsi"></span>
        <p class="zztjtoptext">其他</p>
        <div class="look"><img class="look-img" src="../assets/img/look.gif" /></div>
      </div>
    </div>
    <ul class="av-ctn">
      <li v-for="(item, index) in data5" :key="index">
        <div class="av_img" @click="downloadClick(item.btn)">
          <img v-if="item.isImg" class="iocn2" v-lazy="item.url" alt="" />
        </div>
        <div class="av_text">
          <p class="av_textrig" v-if="item.title">{{ item.title }}</p>
          <div class="av_btn" @click="downloadClick(item.btn)">
            <span></span>
            <p class="av_textxia">下载</p>
          </div>
        </div>
        <div class="av_title">{{ item.text }}</div>
      </li>

    </ul>
    <div class="goBlak">
      <div class="btn_goback" @click="goBack"></div>
    </div>
    <div class="footer"></div>
    <!-- 弹框 -->
    <div :class="['toast', { active: show }]" @click.stop="toastClick">
      <div class="toast_body" @click.stop>
        <p class="toast_text">
          因含成人内容，被系统误判为<br />
          恶意软件，本站承诺<span class="nliner">绝不含恶意程</span><br />
          <span class="nliner">序</span>，请放心安装使用。
        </p>
        <div class="btn_dow" @click="downloadToast">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
import './main.css'
import Player from './Player'
import downloadData from './downloadData.js';
// import { getAnalytics, logEvent,  } from "firebase/analytics";

export default {
  name: 'Header',
  components: { Player },
  props: {
    msg: String
  },
  data() {
    return {
      tabIndex: 0,
      isFixed: false,
      vplay1: false,
      timer: null,
      show: false,
      showName: '',
      data1: [
        { url: require('../assets/video/1.gif'), text: '果酱视频', btn: '果酱视频' },
        { url: require('../assets/img/2.png'), text: '嘿嘿连载', btn: '嘿嘿连载' },
        { url: require('../assets/video/3.gif'), text: 'CiliCili短视频', btn: 'CiliCili短视频' },
        { url: require('../assets/img/4.png'), text: '香蕉视频', btn: '香蕉视频' },
        { url: require('../assets/img/5.png'), text: '王者荣耀短视频', btn: '王者荣耀短视频' },
        { url: require('../assets/video/6.gif'), text: '久草视频', btn: '久草视频' },
        { url: require('../assets/img/7.png'), text: '肉漫社', btn: '肉漫社' },
        { url: require('../assets/video/8.gif'), text: '果酱短视频', btn: '果酱短视频' },
        { url: require('../assets/video/9.gif'), text: 'H动漫', btn: 'H动漫' },
        { url: require('../assets/img/10.png'), text: 'H成人小说', btn: 'H成人小说' },
        { url: require('../assets/video/11.gif'), text: '小笛视频', btn: '小笛视频' },
        { url: require('../assets/video/12.gif'), text: '国产富二代', btn: '国产富二代' },
      ],
      dataAv: [
        { url: require('../assets/video/av/13.mp4'), imguUrl: require('../assets/video/av/13.jpg'), title: '草莓视频', text: '全球AV，日本国产欧美', isImg: false, btn: '草莓视频' },
        { url: require('../assets/video/av/14.jpg'), imguUrl: '', title: '幼女视频', text: '', isImg: true, btn: '幼女视频' },
        { url: require('../assets/video/av/15.jpg'), imguUrl: '', title: '内射视频', text: '', isImg: true, btn: '内射视频' },
        { url: require('../assets/video/av/16.gif'), imguUrl: '', title: '麻豆视频', text: '国产原创，国产AV剧情', isImg: true, btn: '麻豆视频' },
        { url: require('../assets/video/av/17.mp4'), imguUrl: require('../assets/video/av/17.jpg'), title: '逼站暗网免費看', text: '独家稀缺，限制级资源', isImg: false, btn: '逼站暗网免費看' },
        { url: require('../assets/video/av/18.jpg'), imguUrl: '', title: '芒果视频', text: '', isImg: true, btn: '芒果视频' },
        { url: require('../assets/video/av/19.jpg'), imguUrl: '', title: '色中色', text: '', isImg: true, btn: '色中色' },
        { url: require('../assets/video/av/20.gif'), imguUrl: '', title: '麻豆破解版', text: '免费，中字，全网最全片库', isImg: true, btn: '麻豆破解版' },
        { url: require('../assets/video/av/21.mp4'), imguUrl: require('../assets/video/av/21.jpg'), title: 'JAV日本高清无码', text: '日本名优, 网罗全网高端资源', isImg: false, btn: 'JAV日本高清无码' },
        { url: require('../assets/video/av/22.png'), imguUrl: '', title: '中学生视频', text: '', isImg: true, btn: '中学生视频' },
        { url: require('../assets/video/av/23.jpg'), imguUrl: '', title: '无码高清', text: '', isImg: true, btn: '无码高清' },
        { url: require('../assets/video/av/24.gif'), imguUrl: '', title: '樱桃视频', text: '独家首播，日更万片', isImg: true, btn: '樱桃视频' },
        { url: require('../assets/video/av/25.mp4'), imguUrl: require('../assets/video/av/25.jpg'), title: '强奸视频', text: '全平台独家，冒险推出', isImg: false, btn: '强奸视频' },
        { url: require('../assets/video/av/26.jpg'), imguUrl: '', title: '免费AV视频', text: '', isImg: true, btn: '免费AV视频' },
        { url: require('../assets/video/av/27.jpg'), imguUrl: '', title: '奶茶视频', text: '', isImg: true, btn: '奶茶视频' },
        { url: require('../assets/video/av/28.gif'), imguUrl: '', title: 'CiliCili视频', text: '独家精选，顶尖名模', isImg: true, btn: 'CiliCili视频' },
        { url: require('../assets/video/av/29.jpg'), imguUrl: '', title: '萝卜视频', text: '', isImg: true, btn: '萝卜视频' },
        { url: require('../assets/video/av/30.jpg'), imguUrl: '', title: '中文成人娱乐网', text: '', isImg: true, btn: '中文成人娱乐网' },
      ],
      // 成人抖阴
      data3: [
        { url: require('../assets/video/crdy/31.mp4'), imguUrl: require('../assets/video/crdy/31.png'), title: 'cos短视频', text: '私密，原创，偷拍', isImg: false, btn: 'cos短视频' },
        { url: require('../assets/video/crdy/32.gif'), imguUrl: '', title: '久草短视频', text: '高潮(瞬间)全收录, 网罗全网高潮片段', isImg: true, btn: '久草短视频' },
        { url: require('../assets/video/crdy/33.png'), imguUrl: '', title: '初中生短视频', text: '', isImg: true, btn: '初中生短视频' },
        { url: require('../assets/video/crdy/34.gif'), imguUrl: '', title: '快手成人版', text: '全是撸点，没有尿点', isImg: true, btn: '快手成人版' },
        { url: require('../assets/video/crdy/35.png'), imguUrl: '', title: '幼女短视频', text: '', isImg: true, btn: '幼女短视频' },
        { url: require('../assets/video/crdy/36.mp4'), imguUrl: require('../assets/video/crdy/36.png'), title: '免费短视频', text: '热门事件，每日更新', isImg: false, btn: '免费短视频' },
        { url: require('../assets/video/crdy/37.png'), imguUrl: '', title: '孕妇短视频', text: '', isImg: true, btn: '孕妇短视频' },
        { url: require('../assets/video/crdy/38.gif'), imguUrl: '', title: '抖音短视频', text: '成人抖音，激情刷不停', isImg: true, btn: '抖音短视频' },
        { url: require('../assets/video/crdy/39.mp4'), imguUrl: require('../assets/video/crdy/39.png'), title: '小学生短视频', text: '', isImg: false, btn: '小学生短视频' },
        { url: require('../assets/video/crdy/40.mp4'), imguUrl: require('../assets/video/crdy/40.png'), title: '妈妈短视频', text: '', isImg: false, btn: '妈妈短视频' },
        { url: require('../assets/video/crdy/41.png'), imguUrl: '', title: '学生短视频', text: '', isImg: true, btn: '学生短视频' },
        { url: require('../assets/video/crdy/42.gif'), imguUrl: '', title: '3d短视频', text: '超快加载，无需翻墙', isImg: true, btn: '3d短视频' },
        { url: require('../assets/video/crdy/43.png'), imguUrl: '', title: '内射短视频', text: '', isImg: true, btn: '内射短视频' },
        { url: require('../assets/video/crdy/44.gif'), imguUrl: '', title: '处女私拍', text: '', isImg: true, btn: '处女私拍' },
        { url: require('../assets/video/crdy/45.png'), imguUrl: '', title: '人兽短视频', text: '', isImg: true, btn: '人兽短视频' },

      ],
      // 禁漫爽文
      data4: [
        { url: require('../assets/video/jmsw/46.mp4'), imguUrl: require('../assets/video/jmsw/46.png'), title: '18禁漫', text: '18+动漫天堂', isImg: false, btn: '18禁漫' },
        { url: require('../assets/video/jmsw/47.jpg'), imguUrl: '', title: '最全污漫', text: '各种你想不到的成人动漫', isImg: true, btn: '最全污漫' },
        { url: require('../assets/video/jmsw/48.jpg'), imguUrl: '', title: '91學妹禁漫', text: '高辣文禁漫，有声骚浪', isImg: true, btn: '91學妹禁漫' },
        { url: require('../assets/video/jmsw/49.mp4'), imguUrl: require('../assets/video/jmsw/49.png'), title: '情色卡通', text: '情色小说', isImg: false, btn: '情色卡通' },
        { url: require('../assets/video/jmsw/50.gif'), imguUrl: '', title: '色情书库', text: '有声小说', isImg: true, btn: '色情书库' },
        { url: require('../assets/video/jmsw/51.jpg'), imguUrl: '', title: '萝莉社', text: '探索绝品异想世界', isImg: true, btn: '萝莉社' },
      ],
      // 其他
      data5: [
        { url: require('../assets/video/qita/52.gif'), title: '真人H直播', text: '直播大秀，极速秒播', isImg: true, btn: '真人H直播' },
        { url: require('../assets/video/qita/53.jpg'), title: '同城约炮平台', text: '同城约炮 一触即达', isImg: true, btn: '同城约炮平台' },
        { url: require('../assets/video/qita/54.gif'), title: '真人H聊天室', text: '', isImg: true, btn: '真人H聊天室' },
        { url: require('../assets/video/qita/55.png'), title: '熟女俱乐部', text: '', isImg: true, btn: '熟女俱乐部' },
      ],
    }
  },
  watch: {
    // playState1(val,old){
    //   console.log('watch', val,old)
    // }
  },
  methods: {
    replayVideoFunc(type, index) {
      let videoObj = this.$refs[`${type}video${index}`][0];
      if (videoObj.ended) {
        videoObj.pauseVideo(true);
      }
    },
    playState1(parmas) {
      this.vplay1 = parmas;
    },
    showload() {
      // this.stopTab1Video();
      this.show = true;
    },
    stopTab1Video() {
      if (!this.$refs.video1.ended) {
        this.$refs.video1[0].pauseVideo(false);
      }
    },
    // 滚动条事件 吸顶效果
    getScroll() {
      const top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      const heightOff = document.getElementById('nav').offsetHeight;
      if (top > heightOff) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },
    listenerFun() {
      window.addEventListener('scroll', this.SrcollListener, true);
    },
    // 返回顶部
    goBack() {
      const timer0 = setInterval(() => {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop;
        let isPeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop = osTop + isPeed;
        if (osTop === 0) {
          clearInterval(timer0)
        }

      }, 30)
      this.$analytics.logEvent("返回顶部",{name: 'Go_Back'});
    },

    // 楼层驱动,点击方法
    setFloorClick(index) {
      const floor_item = document.getElementsByClassName('zztjtop');
      const floor_offsetTop = floor_item[index].offsetTop - floor_item[0].offsetTop;
      const win_scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const obj = {
        step: 50,
        times: 20,
        FLOOR_OFFSETTOP: floor_offsetTop,
      }
      if (win_scrollTop > floor_offsetTop) {
        this.setUp(obj)
      } else if (win_scrollTop == floor_offsetTop) {
        return false
      } else {
        this.setDown(obj)
      }
      this.$analytics.logEvent("导航",{name: 'Nav'});
    },

    // 向上滚动
    setUp(obj) {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        let win_scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        if (win_scrollTop <= obj.FLOOR_OFFSETTOP) {
          document.documentElement.scrollTop = obj.FLOOR_OFFSETTOP
          document.body.scrollTop = obj.FLOOR_OFFSETTOP
          clearInterval(this.timer)
        } else {
          document.documentElement.scrollTop = win_scrollTop - obj.step
          document.body.scrollTop = win_scrollTop - obj.step
        }
      }, obj.times)
    },
    setDown(obj) {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        let win_scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        // 不加会导致win_scrollTop一直小于obj.FLOOR_OFFSETTOP, 退出不了setInterval
        if (win_scrollTop + 100 >= obj.FLOOR_OFFSETTOP) {
          document.documentElement.scrollTop = obj.FLOOR_OFFSETTOP
          document.body.scrollTop = obj.FLOOR_OFFSETTOP
          clearInterval(this.timer)
        } else {
          document.documentElement.scrollTop = win_scrollTop + obj.step
          document.body.scrollTop = win_scrollTop + obj.step
        }
      }, obj.times)
    },
    // 监听滚动条距离,导航定位
    SrcollListener() {
      // const nav_item = document.getElementById("getli").getElementsByTagName("li");
      const floor_item = document.getElementsByClassName('zztjtop');
      const window_scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      for (let i = 0, len = floor_item.length; i < len; i++) {
        let floor_offsetTop = floor_item[i].offsetTop - floor_item[0].offsetTop;
        if (window_scrollTop >= floor_offsetTop) {
          this.tabIndex = i;
        }
      }
    },
    downloadClick(name) {
      this.show = true;
      let data2 = {}
      downloadData.map((item) => {
        if (item.name == name) {
          data2 = item.data;
        }
      });
        // 判断不是安卓
      if (window.navigator.userAgent.indexOf('Android') == -1) {
        window.location.href = data2.iosApp;
      } else {
        window.location.href = data2.androidApp;
      }

      this.$analytics.logEvent("下载",{name: 'Details_photo'});

    },
    // 确定按钮
    downloadToast() {
      this.show = false;
    },
    toastClick() {
      this.show = false;
    }

  },
  created() {
    this.listenerFun();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.SrcollListener);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
.video_post {
  width: 100%;
  height: 100%;
  position: relative;
}

.video_post .video_box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.video_box .v-cover {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.video_box .v-cover img {
  width: 100%;
  height: 100%;
}

.video_box .v-play-icon {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
}

.video_box .v-play-icon img {
  width: 72px;
  height: 72px;
}

.replay-container {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 6;
}

.replay-container.bg01 {
  background: rgba(0, 0, 0, 0.7);
}

.replay-container .replay-btn {
  font-size: 20px;
  color: #FFFFFF;
  margin: 38px 0 30px 26px;
  text-align: left;
}

.replay-container .replay-btn2 {
  font-size: 20px;
  color: #FFFFFF;
  margin: 90px 0 30px 26px;
  text-align: left;
}

.replay-btn .replay-icon {
  background: url(../assets/img/replay-icon.png) no-repeat;
  background-size: 100% 100%;
  width: 26px;
  height: 28px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 13px;
}

.replay-btn .replay-btn-text {
  display: inline-block;
  vertical-align: middle;
}

.replay-container .replay-tips {
  font-size: 28px;
  color: #FFFFFF;
  background-color: rgba(235, 233, 233, 0.13);
  padding: 4px 15px;
  border-radius: 8px;
  margin: 30px;
}

.replay-container .replay-download {
  background: url(../assets/img/download_02.png) no-repeat;
  background-size: 100% 100%;
  width: 326px;
  height: 50px;
  text-align: center;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.replay-container .replay-download2 {
  background: url(../assets/img/douyi.png) no-repeat;
  background-size: 100% 100%;
  width: 214px;
  height: 54px;
}

.replay-download .icon33 {
  display: inline-block;
  width: 26px;
  height: 26px;
  background: url(../assets/img/download_02_1.png) no-repeat;
  background-size: 100% 100%;
  margin: 0 12px 0 0;
}

.replay-download .dow_text {
  font-size: 26px;
  color: #FFFFFF;
  text-decoration: underline;
}
</style>
